import { MemoryStorage } from 'lib/storage'

import { hashArgs, fillConditionalsWithValue, promisify } from './lib'
import { validators, validateArgs } from './validators'
import { sleep } from 'lib/util'

/** Create a memoized function for fetching data
 *
 * @param {object[]} conditionals - Rules for the arguments. See ./validators for schema
 * @param {function} getter - A function defining how to fetch data
 * @param {object} options
 * @returns {function} - The memoized function
 */
export function createDataTreeCache(conditionals, getter, options = {}) {
  const { Storage = MemoryStorage } = options
  const { name, maxSize = Infinity, logPerformance = false, log = false } = options

  const storage = new Storage({ prefix: name, maxSize, log })

  const promiseMemo = {}

  function get(...originalArgs) {
    conditionals = conditionals.map((conditional) => {
      conditional.validators = conditional.validators || [
        validators.isArray,
        (val) => val.every((val) => validators.isType('string')(val)),
      ]
      return conditional
    })
    validateArgs(fillConditionalsWithValue(conditionals, originalArgs))

    const args = originalArgs.reduce((args, arg, index) => {
      if (typeof conditionals[index].argToKeys === 'function') {
        args.push(conditionals[index].argToKeys(arg, originalArgs))
      } else if (!conditionals[index].ignoreOnCaching) {
        args.push(arg)
      }
      return args
    }, [])
    const additionalArgs = originalArgs.reduce((additionalArgs, arg, index) => {
      if (conditionals[index].ignoreOnCaching) {
        additionalArgs.push(arg)
      }
      return additionalArgs
    }, [])

    if (logPerformance) console.time('prepare data fetching')
    // build keys to test what data has to be fetched
    const requestKeys = []
    const fetchKeys = []
    let fetchArgs = []
    const buildFetchArgs = (arrays, index = 0, currentKey = '', prevKeys = []) => {
      arrays[index].forEach((val) => {
        const curKeys = prevKeys.concat([val])
        const key = currentKey + val
        if (index < arrays.length - 1) {
          buildFetchArgs(arrays, index + 1, key + '#', curKeys)
        } else {
          requestKeys.push(key)
          if (!storage.hasKey(key)) {
            fetchKeys.push(key)
            if (!fetchArgs.length) {
              fetchArgs = curKeys.map(() => new Set())
            }
            curKeys.forEach((key, index) => {
              fetchArgs[index].add(key)
            })
          }
        }
      })
    }
    buildFetchArgs(args)

    // fetch data not already in cache
    let promise
    if (fetchArgs.length) {
      fetchArgs = fetchArgs.map((args, index) => {
        args = Array.from(args)
        if (typeof conditionals[index].keysToArg === 'function') {
          args = conditionals[index].keysToArg(args, originalArgs)
        }
        return args
      })
      let hash = null
      if (options.usePromiseMemo) {
        hash = hashArgs(fillConditionalsWithValue(conditionals, [...fetchArgs, ...additionalArgs]))
        if (promiseMemo[hash]) {
          return promiseMemo[hash]
        }
      }
      promise = promisify(getter(...[...fetchArgs, ...additionalArgs]))
      if (hash !== null) {
        promiseMemo[hash] = promise
      }
    } else {
      promise = sleep(0).then(() => null)
    }
    if (logPerformance) console.timeEnd('prepare data fetching')

    return promise.then((res) => {
      // put new data to cache, if any
      if (logPerformance) console.time('put data to cache')
      const items = []
      fetchKeys.forEach((key) => {
        const resKeys = key.split('#')
        const val = resKeys.reduce((value, resKey) => value?.[resKey], res)
        items.push({ key, val })
      })
      storage.setItems(items)
      if (logPerformance) console.timeEnd('put data to cache')

      // build result depending on requestKeys from cache
      if (logPerformance) console.time('get data from cache')
      const result = {}
      requestKeys.forEach((key) => {
        const resKeys = key.split('#')
        const value = storage.getItem(key)
        let resultPointer = result
        resKeys.forEach((key, index) => {
          if (typeof resultPointer[key] === 'undefined') {
            resultPointer[key] = index < resKeys.length - 1 ? {} : value
          }
          resultPointer = resultPointer[key]
        })
      })
      if (logPerformance) console.timeEnd('get data from cache')
      if (logPerformance) console.log(storage.length)
      return result
    })
  }
  function bust() {
    storage.clear()
  }
  return [get, bust]
}
