import { hashCode } from 'lib/hashCode'
import { createDataTreeCache } from 'lib/cache'

import api from 'stores/api'

const conditionalsMinMaxYears = [
  {
    name: 'areaTypes',
    stringify: (areaTypes = []) => {
      return hashCode(JSON.stringify(areaTypes.toSorted())).toString()
    },
  },
  {
    name: 'dataSources',
    stringify: (dataSources = []) => {
      return hashCode(JSON.stringify(dataSources.toSorted())).toString()
    },
  },
]

const getterMinMaxYears = (areaTypes, dataSources) => {
  return api.Config.dataSourcesYearsBySources(dataSources, areaTypes).then((result) => {
    if (!(result.data.status && result.data.status === 'success')) {
      throw new Error(result.data.messages)
    }
    return result.data.data
  })
}

export const [getMinMaxYears, bustMinMaxYears] = createDataTreeCache(
  conditionalsMinMaxYears,
  getterMinMaxYears,
  {
    usePromiseMemo: true,
    name: 'MarketDataCacheMinMaxYears',
  }
)
