import { createDataCache } from 'lib/cache'

import api from 'stores/api'

const conditionalsLocation = [
  {
    name: 'gac',
  },
  {
    name: 'returnCentroid',
  },
]

const getterLocation = (gac, returnCentroid = true) => {
  return api.Location.byGac(gac, returnCentroid).then((result) => {
    if (result.data.status && result.data.status !== 'success') {
      throw new Error(result.data.messages)
    }
    return result.data.data
  })
}

export const [getLocationByGac] = createDataCache(conditionalsLocation, getterLocation, {
  name: 'LocationByGacCache',
})
